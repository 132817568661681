import React from "react";
import { motion } from "framer-motion";

const About = () => {
  return (
    <section id="about" className="py-20 bg-gradient-to-b from-black to-gray-900">
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="max-w-4xl mx-auto text-center"
        >
          <h2 className="text-4xl font-bold mb-8 text-white">About Us</h2>
          <p className="text-xl text-gray-300 leading-relaxed">
            We're a passionate team dedicated to transforming the bar industry through innovative technology. 
            Our platform creates meaningful connections between bar owners and their customers, 
            making every night out more rewarding for everyone involved.
          </p>
          {/* <div className="mt-12 grid grid-cols-3 gap-8">
            <div className="text-center">
              <h3 className="text-yellow-500 text-4xl font-bold">50+</h3>
              <p className="text-gray-300 mt-2">Partner Bars</p>
            </div>
            <div className="text-center">
              <h3 className="text-yellow-500 text-4xl font-bold">10k+</h3>
              <p className="text-gray-300 mt-2">Active Users</p>
            </div>
            <div className="text-center">
              <h3 className="text-yellow-500 text-4xl font-bold">95%</h3>
              <p className="text-gray-300 mt-2">Satisfaction Rate</p>
            </div>
          </div> */}
        </motion.div>
      </div>
    </section>
  );
};

export default About;
