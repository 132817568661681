import React from "react";
import Navbar from "./components/Navbar";
import Hero from "./components/Hero";
import Features from "./components/Features";
import About from "./components/About";
import Footer from "./components/Footer";

function App() {
  return (
      <div className="flex flex-col min-h-screen">
        <Navbar />
        <Hero />
        <div className="flex-grow">
          <Features />
          <About />
        </div>
        <Footer />
      </div>
  );
}

export default App;
