import React from "react";
import { motion } from "framer-motion";

const FeatureCard = ({ title, description, icon, delay }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    whileInView={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5, delay }}
    className="p-6 bg-gray-900 rounded-xl shadow-xl hover:shadow-2xl transition-shadow"
  >
    <div className="flex items-center space-x-4">
      <div className="bg-yellow-500 p-3 rounded-lg">
        {icon}
      </div>
      <h3 className="text-xl font-semibold text-white">{title}</h3>
    </div>
    <p className="mt-4 text-gray-300">{description}</p>
  </motion.div>
);

const Features = () => {
  const barOwnerFeatures = [
    {
      title: "Rewards Management",
      description: "Create and manage customer loyalty programs with ease.",
      icon: "🎁"
    },
    {
      title: "Event Operations",
      description: "Seamlessly manage events, guest lists, and ticketing.",
      icon: "📅"
    },
    {
      title: "Customer Analytics",
      description: "Get powerful insights through POS integrations.",
      icon: "📊"
    }
  ];

  const customerFeatures = [
    {
      title: "Earn Free Drinks",
      description: "Get rewarded for your loyalty at local bars.",
      icon: "🍻"
    },
    {
      title: "Discover Events",
      description: "Find and join exciting events near you.",
      icon: "🎉"
    },
    {
      title: "Track Spending",
      description: "Monitor your expenses with detailed insights.",
      icon: "💰"
    },
  ];

  return (
    <section id="features" className="py-20 bg-black">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-center text-white mb-16">
          Features
        </h2>
        
        <div className="space-y-20">
          <div>
            <h3 className="text-2xl font-semibold text-yellow-500 mb-8 text-center">
              For Bar Owners
            </h3>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {barOwnerFeatures.map((feature, index) => (
                <FeatureCard key={index} {...feature} delay={index * 0.2} />
              ))}
            </div>
          </div>
          
          <div>
            <h3 className="text-2xl font-semibold text-yellow-500 mb-8 text-center">
              For Customers
            </h3>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {customerFeatures.map((feature, index) => (
                <FeatureCard key={index} {...feature} delay={index * 0.2} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;
