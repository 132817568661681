import React, { useState } from "react";
import Modal from "./Modal";
import mainImage from "../assets/logo_full.png";

const Hero = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <section className="min-h-screen bg-gradient-to-b from-black to-gray-900 text-white pt-20">
      <div className="container mx-auto px-4 flex flex-col md:flex-row items-center justify-between py-16 md:py-32">
        <div className="md:w-1/2 space-y-6 text-center md:text-left">
          <h1 className="text-4xl md:text-6xl font-bold leading-tight animate-fade-in">
            Connecting Bars with Their Best Customers
          </h1>
          <p className="text-xl md:text-2xl text-gray-300 max-w-xl">
            We're revolutionizing the bar industry through seamless connections and rewards.
          </p>
          <div className="flex flex-col sm:flex-row gap-4 justify-center md:justify-start">
            <button 
              onClick={() => window.open("https://apps.apple.com/us/app/barcard-app/id6737355596", "_blank")}
              className="bg-yellow-500 hover:bg-yellow-400 text-black font-semibold py-3 px-8 rounded-lg transform transition-all hover:scale-105 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-opacity-50"
            >
              Download iOS App
            </button>
            <button 
              onClick={() => setIsModalOpen(true)}
              className="bg-transparent border-2 border-yellow-500 text-yellow-500 hover:bg-yellow-500 hover:text-black font-semibold py-3 px-8 rounded-lg transform transition-all hover:scale-105 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-opacity-50"
            >
              Join Android Waitlist
            </button>
          </div>
        </div>
        <div className="md:w-1/2 mt-12 md:mt-0">
          <img 
            src={mainImage} 
            alt="Barcard App Preview" 
            className="w-full max-w-lg mx-auto transform hover:scale-105 transition-transform duration-300"
          />
        </div>
      </div>
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </section>
  );
};

export default Hero;
