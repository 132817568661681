import React, { useState, useEffect } from "react";
import logo from "../assets/logo_barcard.png";

const Navbar = () => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <nav className={`fixed w-full z-50 transition-all duration-300 ${
      scrolled ? 'bg-black shadow-lg' : 'bg-transparent'
    }`}>
      <div className="container mx-auto px-4 py-3 flex justify-between items-center">
        <a href="/" className="flex items-center">
          <img src={logo} alt="Barcard Logo" className="h-12 md:h-14 transition-transform hover:scale-105" />
        </a>
        <div className="hidden md:flex space-x-8">
          <a href="#features" className="text-white hover:text-yellow-500 transition-colors text-lg font-medium">
            Features
          </a>
          <a href="#about" className="text-white hover:text-yellow-500 transition-colors text-lg font-medium">
            About
          </a>
        </div>
        <button className="md:hidden text-white">
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16m-7 6h7" />
          </svg>
        </button>
      </div>
    </nav>
  );
};

export default Navbar;
